'use client';

import { createBlock } from '@/components/Blocks/createBlock';
import { gql } from '@/graphql/__generated__';
import { Widget } from '@typeform/embed-react';
import { BlockContainer } from '../../BlockContainer';

const TYPEFORM_BLOCK = gql(`
  fragment typeformBlock on blocks_typeform_BlockType {
      label
  }   
`);

const TypetypeformBlock = createBlock(TYPEFORM_BLOCK, ({ data, meta }) => {
  if (!data || !data.label) return null;

  return (
    <BlockContainer meta={meta} marginY="5xl">
      <Widget id={data.label} style={{ minHeight: '500px' }} />
    </BlockContainer>
  );
});

export default TypetypeformBlock;
