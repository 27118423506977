import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardDateProps<D extends React.ElementType = 'span'> = TxtProps<D>;

export const CardDate = <D extends React.ElementType>({ children, ...props }: CardDateProps<D>) => {
  const { date } = useCard();
  const content = children ?? date;

  if (!content) return null;

  return (
    <Txt as="span" variant="sm" color="grey" {...mergePropsClassName(props, style.meta)}>
      {content}
    </Txt>
  );
};
