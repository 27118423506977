/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  fragment articleCard on article_Entry {\n    __typename\n    id\n    uri\n    postDate @formatDateTime(format: \"j F Y\")\n    entryTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n    articleCategory {\n      ...articleCategory\n    }\n  }\n": types.ArticleCardFragmentDoc,
    "\n  fragment accordionItemEntry on accordionItem_Entry {\n    id\n    title\n    content: htmlContentDefault\n    # imageSingle {\n    #   ...ImageAsset\n    # }\n    # video\n  }\n": types.AccordionItemEntryFragmentDoc,
    "\n  fragment accordionBlock on blocks_accordion_BlockType {\n    heading\n    accordionItems {\n      ...accordionItemEntry\n    }\n    blockScheme {\n      label\n    }\n  }   \n": types.AccordionBlockFragmentDoc,
    "\n  fragment buttonBlock on blocks_button_BlockType {\n      linkField {\n        ...linkField\n      }\n  }   \n": types.ButtonBlockFragmentDoc,
    "\n  fragment ctaBlock on blocks_cta_BlockType {\n      ctaTitle\n      ctaSubTitle\n      linkField {\n        ...linkField\n      }\n\n      ## App\n      appTitle\n      appSubTitle\n      appStoreExternalUrl\n      googlePlayExternalUrl\n  }   \n": types.CtaBlockFragmentDoc,
    "\n  fragment downloadsBlock on blocks_downloads_BlockType {\n      heading\n      documentMultiple {\n        ...DocumentAsset\n      }\n  }   \n": types.DownloadsBlockFragmentDoc,
    "\n  fragment headingBlock on blocks_heading_BlockType {\n      heading\n  }   \n": types.HeadingBlockFragmentDoc,
    "\n  fragment imageBlock on blocks_image_BlockType {\n      imageSingle {\n        ...ImageAsset\n      }\n      imageWidth\n      caption: label\n  }   \n": types.ImageBlockFragmentDoc,
    "\n  fragment lineBlock on blocks_line_BlockType {\n      __typename\n  }   \n": types.LineBlockFragmentDoc,
    "\n  fragment logoWallBlock on blocks_logoWall_BlockType {\n      heading\n      imageMultiple {\n        ...ImageAsset\n      }\n  }   \n": types.LogoWallBlockFragmentDoc,
    "\n  fragment newsletterSignupBlock on blocks_newsletterSignup_BlockType {\n      __typename\n  }   \n": types.NewsletterSignupBlockFragmentDoc,
    "\n    fragment priceBlock on blocks_price_BlockType {\n      heading\n      minValue\n      price\n      maxValue\n      linkField {\n        ...linkField\n      }\n    }   \n": types.PriceBlockFragmentDoc,
    "\n  fragment pricingBlock on blocks_pricing_BlockType {\n    heading\n    children {\n      ...priceBlock\n    }\n    enquireTitle\n    enquireSubTitle\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n": types.PricingBlockFragmentDoc,
    "\n  fragment quoteBlock on blocks_quote_BlockType {\n      heading\n      label\n  }   \n": types.QuoteBlockFragmentDoc,
    "\n  fragment richTextBlock on blocks_richText_BlockType {\n      heading\n      content: htmlContentDefault\n      blockScheme {\n        label\n      }\n  }   \n": types.RichTextBlockFragmentDoc,
    "\n  fragment scrollItemEntry on scrollItem_Entry {\n    imageSingle {\n      ...ImageAsset\n    }\n    title\n    content: htmlContentSimple\n    id\n  }\n": types.ScrollItemEntryFragmentDoc,
    "\n  fragment scrollContentBlock on blocks_scrollContent_BlockType {\n      heading\n      scrollItemMultiple {\n        ...scrollItemEntry\n      }\n  }   \n": types.ScrollContentBlockFragmentDoc,
    "\n  fragment profileEntry on profile_Entry {\n    entryTitle\n    label\n    bio: htmlContentSimple\n    entryImage {\n      ...ImageAsset\n    }\n  }     \n": types.ProfileEntryFragmentDoc,
    "\n  fragment staffBlock on blocks_staff_BlockType {\n    heading\n    blockScheme {\n      label\n    }\n    profileMultiple {\n      ...profileEntry\n    }\n  }   \n": types.StaffBlockFragmentDoc,
    "\n  fragment tabEntry on tab_Entry {\n    id\n    imageSingle {\n      ...ImageAsset\n    }\n    iconSingle {\n      ...ImageAsset\n    }\n    title\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }  \n": types.TabEntryFragmentDoc,
    "\n  fragment tabbedContentBlock on blocks_tabbedContent_BlockType {\n    heading\n    tabMultiple {\n      ...tabEntry\n    }\n  }\n": types.TabbedContentBlockFragmentDoc,
    "\n  fragment testimonialItemBlock on blocks_testimonialItem_BlockType {\n      imageSingle {\n        ...ImageAsset\n      }\n      testimonial: heading\n      author: label\n      itemTitle\n  }   \n": types.TestimonialItemBlockFragmentDoc,
    "\n  fragment testimonialBlock on blocks_testimonial_BlockType {\n      heading\n      children {\n        ...testimonialItemBlock\n      }\n  }   \n": types.TestimonialBlockFragmentDoc,
    "\n  fragment threeColumnItemBlock on blocks_threeColumnItem_BlockType {\n    id\n    imageSingle {\n      ...ImageAsset\n    }\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }   \n": types.ThreeColumnItemBlockFragmentDoc,
    "\n  fragment threeColumnContentBlock on blocks_threeColumnContent_BlockType {\n      heading\n      children {\n        ...threeColumnItemBlock\n      }\n      linkField {\n        ...linkField\n      }\n      blockScheme {\n        label\n      }\n  }   \n": types.ThreeColumnContentBlockFragmentDoc,
    "\n  fragment twoColumnContentBlock on blocks_twoColumnContent_BlockType {\n      heading\n      content: htmlContentDefault\n      linkField {\n        ...linkField\n      }\n      imageSingle {\n        ...ImageAsset\n      }\n      video\n      caption: label\n      blockScheme {\n        label\n      }\n      flipAlignment: lightswitch\n  }   \n": types.TwoColumnContentBlockFragmentDoc,
    "\n  fragment twoColumnGroupBlock on blocks_twoColumnGroup_BlockType {\n      heading\n      linkField {\n        ...linkField\n      }\n      blockScheme {\n        label\n      }\n      children {\n        ...twoColumnContentBlock\n      }\n  }   \n": types.TwoColumnGroupBlockFragmentDoc,
    "\n  fragment twoImagesBlock on blocks_twoImages_BlockType {\n      leftImage: imageSingle {\n        ...ImageAsset\n      }\n      leftImageCaption: label\n      rightImage: imageSingle2 {\n        ...ImageAsset\n      }\n      rightImageCaption: label2\n  }   \n": types.TwoImagesBlockFragmentDoc,
    "\n  fragment typeformBlock on blocks_typeform_BlockType {\n      label\n  }   \n": types.TypeformBlockFragmentDoc,
    "\n  fragment videoBlock on blocks_video_BlockType {\n      video\n  }   \n": types.VideoBlockFragmentDoc,
    "\n  fragment DocumentAsset on AssetInterface {\n    ...on documentsVolume_Asset {\n      __typename\n      id\n      mimeType\n      alt\n      title\n      url\n      filename\n      extension\n      size\n    }\n  }\n": types.DocumentAssetFragmentDoc,
    "\n    fragment articleEntry on article_Entry {\n        postDate @formatDateTime(format: \"j F Y\")\n        sectionHandle\n        parent {\n          id\n          uri\n          title\n        }\n        entryTitle\n        entrySummary\n        entryImage {\n          ...ImageAsset\n        }\n        articleCategory {\n          ...articleCategory\n        }\n        newsletter: lightswitch\n        blocks {\n            ...blocks\n        }\n        # Related\n        heading\n        articleMultiple {\n          ...articleCard\n        }\n    }\n": types.ArticleEntryFragmentDoc,
    "\n  fragment articleIndexEntry on articleIndex_Entry {\n    sectionHandle\n    parent {\n      id\n      uri\n      title\n    }\n    entryTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n  }\n": types.ArticleIndexEntryFragmentDoc,
    "\n    fragment homeEntry on overview_Entry {\n        sectionHandle\n        entryTitle\n        parent {\n          id\n          uri\n          title\n        }\n        entrySubTitle\n        entrySummary\n        entryImage {\n          ...ImageAsset\n        }\n        linkField {\n          ...linkField\n        }\n        video\n        colorScheme {\n            label\n        }\n        blocks {\n            ...blocks\n        }\n    }\n": types.HomeEntryFragmentDoc,
    "\n    fragment overviewEntry on overview_Entry {\n        sectionHandle\n        parent {\n          id\n          uri\n          title\n        }\n        entryTitle\n        entrySubTitle\n        entrySummary\n        entryImage {\n          ...ImageAsset\n        }\n        video\n        colorScheme {\n            label\n        }\n        blocks {\n            ...blocks\n        }\n    }\n": types.OverviewEntryFragmentDoc,
    "\n  fragment policyEntry on policy_Entry {\n    sectionHandle\n    parent {\n      id\n      uri\n      title\n    }\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n    colorScheme {\n        label\n    }\n    blocks {\n        ...blocks\n    }\n  }\n": types.PolicyEntryFragmentDoc,
    "\n  fragment standardEntry on standard_Entry {\n    sectionHandle\n    parent {\n      id\n      uri\n      title\n    }\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n    colorScheme {\n        label\n    }\n    blocks {\n        ...blocks\n    }\n  }\n": types.StandardEntryFragmentDoc,
    "\n  fragment ImageAsset on AssetInterface {\n    ...on assetsVolume_Asset {\n      __typename\n      uid\n      url\n      height\n      width\n      alt\n    }\n  }\n": types.ImageAssetFragmentDoc,
    "\n    fragment blocks on blocks_NeoField {\n        ...richTextBlock\n        ...headingBlock\n        ...quoteBlock\n        ...buttonBlock\n        ...ctaBlock\n        ...lineBlock\n        ...priceBlock\n        ...pricingBlock\n        ...accordionBlock\n        ...tabbedContentBlock\n        ...twoColumnContentBlock\n        ...twoColumnGroupBlock\n        ...threeColumnItemBlock\n        ...threeColumnContentBlock\n        ...scrollContentBlock\n        ...testimonialItemBlock\n        ...testimonialBlock\n        ...staffBlock\n        ...typeformBlock\n        ...newsletterSignupBlock\n        ...videoBlock\n        ...imageBlock\n        ...twoImagesBlock\n        ...logoWallBlock\n        ...downloadsBlock\n    }    \n": types.BlocksFragmentDoc,
    "\n    fragment articleCategory on articleCategory_Category {\n      __typename\n      id\n      uri\n      slug\n      groupHandle\n      title\n    }\n": types.ArticleCategoryFragmentDoc,
    "\n    fragment entryId on EntryInterface {\n        __typename\n        id\n        title\n        parent {\n            id\n            title\n        }\n    }\n": types.EntryIdFragmentDoc,
    "\n    fragment entryBase on EntryInterface {\n        __typename\n        typeHandle\n        searchScore\n        id\n        uri\n        url\n        slug\n        title\n        sectionHandle\n        postDate @formatDateTime(format: \"j F Y\")\n        parent {\n            id\n            uri\n            title\n        }\n    }\n": types.EntryBaseFragmentDoc,
    "\n    fragment entries on EntryInterface {\n        ...articleEntry\n        ...articleIndexEntry\n        ...homeEntry\n        ...overviewEntry\n        ...standardEntry\n        ...policyEntry\n    }\n": types.EntriesFragmentDoc,
    "\n  query CommonLinks($site: [String] = \"default\") {\n    home: entry(site: $site, uri: \"__home__\") {\n      id\n      uri\n      title\n    }\n    blog: entry(site: $site, uri: \"blog\") {\n      id\n      uri\n      title\n    }\n  }\n": types.CommonLinksDocument,
    "\n  query Entry(\n    $site: [String] = \"default\"\n    $preferSites: [QueryArgument] = [\"default\"]\n    $unique: Boolean\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $type: [String]\n) {\n  entry(\n    site: $site\n    preferSites: $preferSites\n    unique: $unique\n    uri: $uri\n    slug: $slug\n    section: $section\n    type: $type\n    ) {\n      ...entries\n  }\n}\n": types.EntryDocument,
    "\n  fragment globalSocialMedia on socialMedia_GlobalSet {\n    instagramUrl\n    facebookUrl\n    twitterUrl\n    linkedinUrl\n    __typename\n    id\n    handle\n  }\n": types.GlobalSocialMediaFragmentDoc,
    "\n  fragment globalAbn on abn_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n": types.GlobalAbnFragmentDoc,
    "\n  fragment globalPhoneNumber on phoneNumber_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n": types.GlobalPhoneNumberFragmentDoc,
    "\n  fragment globalCopyright on copyright_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n": types.GlobalCopyrightFragmentDoc,
    "\n  fragment globals on GlobalSetInterface {\n    ...globalSocialMedia\n    ...globalAbn\n    ...globalPhoneNumber\n    ...globalCopyright\n  }\n": types.GlobalsFragmentDoc,
    "\n  query Global($handle: [String]) {\n    globalSet(handle: $handle) {\n      ...globals\n    }\n  }\n": types.GlobalDocument,
    "\n  query Nav($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      __typename\n      id\n      uri\n      url\n      level\n      title\n      newWindow\n      typeLabel\n      classes\n      parent {\n        id\n      }\n      element {\n        uri\n        language\n      }\n      ... on primaryNav_Node {        \n        colorScheme {\n          label\n        }\n      }\n      ... on loginNav_Node {        \n        colorScheme {\n          label\n        }\n      }\n    }\n  }\n": types.NavDocument,
    "\n    fragment linkFieldUrl on linkField_Url_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n": types.LinkFieldUrlFragmentDoc,
    "\n    fragment linkFieldEntry on linkField_Entry_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n": types.LinkFieldEntryFragmentDoc,
    "\n    fragment linkFieldCustom on linkField_Custom_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n": types.LinkFieldCustomFragmentDoc,
    "\n    fragment linkFieldEmail on linkField_Email_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n": types.LinkFieldEmailFragmentDoc,
    "\n    fragment linkFieldPhone on linkField_Phone_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n": types.LinkFieldPhoneFragmentDoc,
    "\n    fragment linkField on HyperLinkInterface {\n        ...linkFieldUrl\n        ...linkFieldEntry\n        ...linkFieldCustom\n        ...linkFieldEmail\n        ...linkFieldPhone\n    }\n": types.LinkFieldFragmentDoc,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleCard on article_Entry {\n    __typename\n    id\n    uri\n    postDate @formatDateTime(format: \"j F Y\")\n    entryTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n    articleCategory {\n      ...articleCategory\n    }\n  }\n"): (typeof documents)["\n  fragment articleCard on article_Entry {\n    __typename\n    id\n    uri\n    postDate @formatDateTime(format: \"j F Y\")\n    entryTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n    articleCategory {\n      ...articleCategory\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment accordionItemEntry on accordionItem_Entry {\n    id\n    title\n    content: htmlContentDefault\n    # imageSingle {\n    #   ...ImageAsset\n    # }\n    # video\n  }\n"): (typeof documents)["\n  fragment accordionItemEntry on accordionItem_Entry {\n    id\n    title\n    content: htmlContentDefault\n    # imageSingle {\n    #   ...ImageAsset\n    # }\n    # video\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment accordionBlock on blocks_accordion_BlockType {\n    heading\n    accordionItems {\n      ...accordionItemEntry\n    }\n    blockScheme {\n      label\n    }\n  }   \n"): (typeof documents)["\n  fragment accordionBlock on blocks_accordion_BlockType {\n    heading\n    accordionItems {\n      ...accordionItemEntry\n    }\n    blockScheme {\n      label\n    }\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment buttonBlock on blocks_button_BlockType {\n      linkField {\n        ...linkField\n      }\n  }   \n"): (typeof documents)["\n  fragment buttonBlock on blocks_button_BlockType {\n      linkField {\n        ...linkField\n      }\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ctaBlock on blocks_cta_BlockType {\n      ctaTitle\n      ctaSubTitle\n      linkField {\n        ...linkField\n      }\n\n      ## App\n      appTitle\n      appSubTitle\n      appStoreExternalUrl\n      googlePlayExternalUrl\n  }   \n"): (typeof documents)["\n  fragment ctaBlock on blocks_cta_BlockType {\n      ctaTitle\n      ctaSubTitle\n      linkField {\n        ...linkField\n      }\n\n      ## App\n      appTitle\n      appSubTitle\n      appStoreExternalUrl\n      googlePlayExternalUrl\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment downloadsBlock on blocks_downloads_BlockType {\n      heading\n      documentMultiple {\n        ...DocumentAsset\n      }\n  }   \n"): (typeof documents)["\n  fragment downloadsBlock on blocks_downloads_BlockType {\n      heading\n      documentMultiple {\n        ...DocumentAsset\n      }\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment headingBlock on blocks_heading_BlockType {\n      heading\n  }   \n"): (typeof documents)["\n  fragment headingBlock on blocks_heading_BlockType {\n      heading\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageBlock on blocks_image_BlockType {\n      imageSingle {\n        ...ImageAsset\n      }\n      imageWidth\n      caption: label\n  }   \n"): (typeof documents)["\n  fragment imageBlock on blocks_image_BlockType {\n      imageSingle {\n        ...ImageAsset\n      }\n      imageWidth\n      caption: label\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment lineBlock on blocks_line_BlockType {\n      __typename\n  }   \n"): (typeof documents)["\n  fragment lineBlock on blocks_line_BlockType {\n      __typename\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment logoWallBlock on blocks_logoWall_BlockType {\n      heading\n      imageMultiple {\n        ...ImageAsset\n      }\n  }   \n"): (typeof documents)["\n  fragment logoWallBlock on blocks_logoWall_BlockType {\n      heading\n      imageMultiple {\n        ...ImageAsset\n      }\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment newsletterSignupBlock on blocks_newsletterSignup_BlockType {\n      __typename\n  }   \n"): (typeof documents)["\n  fragment newsletterSignupBlock on blocks_newsletterSignup_BlockType {\n      __typename\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment priceBlock on blocks_price_BlockType {\n      heading\n      minValue\n      price\n      maxValue\n      linkField {\n        ...linkField\n      }\n    }   \n"): (typeof documents)["\n    fragment priceBlock on blocks_price_BlockType {\n      heading\n      minValue\n      price\n      maxValue\n      linkField {\n        ...linkField\n      }\n    }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pricingBlock on blocks_pricing_BlockType {\n    heading\n    children {\n      ...priceBlock\n    }\n    enquireTitle\n    enquireSubTitle\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n"): (typeof documents)["\n  fragment pricingBlock on blocks_pricing_BlockType {\n    heading\n    children {\n      ...priceBlock\n    }\n    enquireTitle\n    enquireSubTitle\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment quoteBlock on blocks_quote_BlockType {\n      heading\n      label\n  }   \n"): (typeof documents)["\n  fragment quoteBlock on blocks_quote_BlockType {\n      heading\n      label\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment richTextBlock on blocks_richText_BlockType {\n      heading\n      content: htmlContentDefault\n      blockScheme {\n        label\n      }\n  }   \n"): (typeof documents)["\n  fragment richTextBlock on blocks_richText_BlockType {\n      heading\n      content: htmlContentDefault\n      blockScheme {\n        label\n      }\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment scrollItemEntry on scrollItem_Entry {\n    imageSingle {\n      ...ImageAsset\n    }\n    title\n    content: htmlContentSimple\n    id\n  }\n"): (typeof documents)["\n  fragment scrollItemEntry on scrollItem_Entry {\n    imageSingle {\n      ...ImageAsset\n    }\n    title\n    content: htmlContentSimple\n    id\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment scrollContentBlock on blocks_scrollContent_BlockType {\n      heading\n      scrollItemMultiple {\n        ...scrollItemEntry\n      }\n  }   \n"): (typeof documents)["\n  fragment scrollContentBlock on blocks_scrollContent_BlockType {\n      heading\n      scrollItemMultiple {\n        ...scrollItemEntry\n      }\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment profileEntry on profile_Entry {\n    entryTitle\n    label\n    bio: htmlContentSimple\n    entryImage {\n      ...ImageAsset\n    }\n  }     \n"): (typeof documents)["\n  fragment profileEntry on profile_Entry {\n    entryTitle\n    label\n    bio: htmlContentSimple\n    entryImage {\n      ...ImageAsset\n    }\n  }     \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment staffBlock on blocks_staff_BlockType {\n    heading\n    blockScheme {\n      label\n    }\n    profileMultiple {\n      ...profileEntry\n    }\n  }   \n"): (typeof documents)["\n  fragment staffBlock on blocks_staff_BlockType {\n    heading\n    blockScheme {\n      label\n    }\n    profileMultiple {\n      ...profileEntry\n    }\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment tabEntry on tab_Entry {\n    id\n    imageSingle {\n      ...ImageAsset\n    }\n    iconSingle {\n      ...ImageAsset\n    }\n    title\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }  \n"): (typeof documents)["\n  fragment tabEntry on tab_Entry {\n    id\n    imageSingle {\n      ...ImageAsset\n    }\n    iconSingle {\n      ...ImageAsset\n    }\n    title\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment tabbedContentBlock on blocks_tabbedContent_BlockType {\n    heading\n    tabMultiple {\n      ...tabEntry\n    }\n  }\n"): (typeof documents)["\n  fragment tabbedContentBlock on blocks_tabbedContent_BlockType {\n    heading\n    tabMultiple {\n      ...tabEntry\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment testimonialItemBlock on blocks_testimonialItem_BlockType {\n      imageSingle {\n        ...ImageAsset\n      }\n      testimonial: heading\n      author: label\n      itemTitle\n  }   \n"): (typeof documents)["\n  fragment testimonialItemBlock on blocks_testimonialItem_BlockType {\n      imageSingle {\n        ...ImageAsset\n      }\n      testimonial: heading\n      author: label\n      itemTitle\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment testimonialBlock on blocks_testimonial_BlockType {\n      heading\n      children {\n        ...testimonialItemBlock\n      }\n  }   \n"): (typeof documents)["\n  fragment testimonialBlock on blocks_testimonial_BlockType {\n      heading\n      children {\n        ...testimonialItemBlock\n      }\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment threeColumnItemBlock on blocks_threeColumnItem_BlockType {\n    id\n    imageSingle {\n      ...ImageAsset\n    }\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }   \n"): (typeof documents)["\n  fragment threeColumnItemBlock on blocks_threeColumnItem_BlockType {\n    id\n    imageSingle {\n      ...ImageAsset\n    }\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment threeColumnContentBlock on blocks_threeColumnContent_BlockType {\n      heading\n      children {\n        ...threeColumnItemBlock\n      }\n      linkField {\n        ...linkField\n      }\n      blockScheme {\n        label\n      }\n  }   \n"): (typeof documents)["\n  fragment threeColumnContentBlock on blocks_threeColumnContent_BlockType {\n      heading\n      children {\n        ...threeColumnItemBlock\n      }\n      linkField {\n        ...linkField\n      }\n      blockScheme {\n        label\n      }\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment twoColumnContentBlock on blocks_twoColumnContent_BlockType {\n      heading\n      content: htmlContentDefault\n      linkField {\n        ...linkField\n      }\n      imageSingle {\n        ...ImageAsset\n      }\n      video\n      caption: label\n      blockScheme {\n        label\n      }\n      flipAlignment: lightswitch\n  }   \n"): (typeof documents)["\n  fragment twoColumnContentBlock on blocks_twoColumnContent_BlockType {\n      heading\n      content: htmlContentDefault\n      linkField {\n        ...linkField\n      }\n      imageSingle {\n        ...ImageAsset\n      }\n      video\n      caption: label\n      blockScheme {\n        label\n      }\n      flipAlignment: lightswitch\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment twoColumnGroupBlock on blocks_twoColumnGroup_BlockType {\n      heading\n      linkField {\n        ...linkField\n      }\n      blockScheme {\n        label\n      }\n      children {\n        ...twoColumnContentBlock\n      }\n  }   \n"): (typeof documents)["\n  fragment twoColumnGroupBlock on blocks_twoColumnGroup_BlockType {\n      heading\n      linkField {\n        ...linkField\n      }\n      blockScheme {\n        label\n      }\n      children {\n        ...twoColumnContentBlock\n      }\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment twoImagesBlock on blocks_twoImages_BlockType {\n      leftImage: imageSingle {\n        ...ImageAsset\n      }\n      leftImageCaption: label\n      rightImage: imageSingle2 {\n        ...ImageAsset\n      }\n      rightImageCaption: label2\n  }   \n"): (typeof documents)["\n  fragment twoImagesBlock on blocks_twoImages_BlockType {\n      leftImage: imageSingle {\n        ...ImageAsset\n      }\n      leftImageCaption: label\n      rightImage: imageSingle2 {\n        ...ImageAsset\n      }\n      rightImageCaption: label2\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment typeformBlock on blocks_typeform_BlockType {\n      label\n  }   \n"): (typeof documents)["\n  fragment typeformBlock on blocks_typeform_BlockType {\n      label\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment videoBlock on blocks_video_BlockType {\n      video\n  }   \n"): (typeof documents)["\n  fragment videoBlock on blocks_video_BlockType {\n      video\n  }   \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DocumentAsset on AssetInterface {\n    ...on documentsVolume_Asset {\n      __typename\n      id\n      mimeType\n      alt\n      title\n      url\n      filename\n      extension\n      size\n    }\n  }\n"): (typeof documents)["\n  fragment DocumentAsset on AssetInterface {\n    ...on documentsVolume_Asset {\n      __typename\n      id\n      mimeType\n      alt\n      title\n      url\n      filename\n      extension\n      size\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment articleEntry on article_Entry {\n        postDate @formatDateTime(format: \"j F Y\")\n        sectionHandle\n        parent {\n          id\n          uri\n          title\n        }\n        entryTitle\n        entrySummary\n        entryImage {\n          ...ImageAsset\n        }\n        articleCategory {\n          ...articleCategory\n        }\n        newsletter: lightswitch\n        blocks {\n            ...blocks\n        }\n        # Related\n        heading\n        articleMultiple {\n          ...articleCard\n        }\n    }\n"): (typeof documents)["\n    fragment articleEntry on article_Entry {\n        postDate @formatDateTime(format: \"j F Y\")\n        sectionHandle\n        parent {\n          id\n          uri\n          title\n        }\n        entryTitle\n        entrySummary\n        entryImage {\n          ...ImageAsset\n        }\n        articleCategory {\n          ...articleCategory\n        }\n        newsletter: lightswitch\n        blocks {\n            ...blocks\n        }\n        # Related\n        heading\n        articleMultiple {\n          ...articleCard\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleIndexEntry on articleIndex_Entry {\n    sectionHandle\n    parent {\n      id\n      uri\n      title\n    }\n    entryTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n  }\n"): (typeof documents)["\n  fragment articleIndexEntry on articleIndex_Entry {\n    sectionHandle\n    parent {\n      id\n      uri\n      title\n    }\n    entryTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment homeEntry on overview_Entry {\n        sectionHandle\n        entryTitle\n        parent {\n          id\n          uri\n          title\n        }\n        entrySubTitle\n        entrySummary\n        entryImage {\n          ...ImageAsset\n        }\n        linkField {\n          ...linkField\n        }\n        video\n        colorScheme {\n            label\n        }\n        blocks {\n            ...blocks\n        }\n    }\n"): (typeof documents)["\n    fragment homeEntry on overview_Entry {\n        sectionHandle\n        entryTitle\n        parent {\n          id\n          uri\n          title\n        }\n        entrySubTitle\n        entrySummary\n        entryImage {\n          ...ImageAsset\n        }\n        linkField {\n          ...linkField\n        }\n        video\n        colorScheme {\n            label\n        }\n        blocks {\n            ...blocks\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment overviewEntry on overview_Entry {\n        sectionHandle\n        parent {\n          id\n          uri\n          title\n        }\n        entryTitle\n        entrySubTitle\n        entrySummary\n        entryImage {\n          ...ImageAsset\n        }\n        video\n        colorScheme {\n            label\n        }\n        blocks {\n            ...blocks\n        }\n    }\n"): (typeof documents)["\n    fragment overviewEntry on overview_Entry {\n        sectionHandle\n        parent {\n          id\n          uri\n          title\n        }\n        entryTitle\n        entrySubTitle\n        entrySummary\n        entryImage {\n          ...ImageAsset\n        }\n        video\n        colorScheme {\n            label\n        }\n        blocks {\n            ...blocks\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment policyEntry on policy_Entry {\n    sectionHandle\n    parent {\n      id\n      uri\n      title\n    }\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n    colorScheme {\n        label\n    }\n    blocks {\n        ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment policyEntry on policy_Entry {\n    sectionHandle\n    parent {\n      id\n      uri\n      title\n    }\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n    colorScheme {\n        label\n    }\n    blocks {\n        ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment standardEntry on standard_Entry {\n    sectionHandle\n    parent {\n      id\n      uri\n      title\n    }\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n    colorScheme {\n        label\n    }\n    blocks {\n        ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment standardEntry on standard_Entry {\n    sectionHandle\n    parent {\n      id\n      uri\n      title\n    }\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...ImageAsset\n    }\n    colorScheme {\n        label\n    }\n    blocks {\n        ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ImageAsset on AssetInterface {\n    ...on assetsVolume_Asset {\n      __typename\n      uid\n      url\n      height\n      width\n      alt\n    }\n  }\n"): (typeof documents)["\n  fragment ImageAsset on AssetInterface {\n    ...on assetsVolume_Asset {\n      __typename\n      uid\n      url\n      height\n      width\n      alt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment blocks on blocks_NeoField {\n        ...richTextBlock\n        ...headingBlock\n        ...quoteBlock\n        ...buttonBlock\n        ...ctaBlock\n        ...lineBlock\n        ...priceBlock\n        ...pricingBlock\n        ...accordionBlock\n        ...tabbedContentBlock\n        ...twoColumnContentBlock\n        ...twoColumnGroupBlock\n        ...threeColumnItemBlock\n        ...threeColumnContentBlock\n        ...scrollContentBlock\n        ...testimonialItemBlock\n        ...testimonialBlock\n        ...staffBlock\n        ...typeformBlock\n        ...newsletterSignupBlock\n        ...videoBlock\n        ...imageBlock\n        ...twoImagesBlock\n        ...logoWallBlock\n        ...downloadsBlock\n    }    \n"): (typeof documents)["\n    fragment blocks on blocks_NeoField {\n        ...richTextBlock\n        ...headingBlock\n        ...quoteBlock\n        ...buttonBlock\n        ...ctaBlock\n        ...lineBlock\n        ...priceBlock\n        ...pricingBlock\n        ...accordionBlock\n        ...tabbedContentBlock\n        ...twoColumnContentBlock\n        ...twoColumnGroupBlock\n        ...threeColumnItemBlock\n        ...threeColumnContentBlock\n        ...scrollContentBlock\n        ...testimonialItemBlock\n        ...testimonialBlock\n        ...staffBlock\n        ...typeformBlock\n        ...newsletterSignupBlock\n        ...videoBlock\n        ...imageBlock\n        ...twoImagesBlock\n        ...logoWallBlock\n        ...downloadsBlock\n    }    \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment articleCategory on articleCategory_Category {\n      __typename\n      id\n      uri\n      slug\n      groupHandle\n      title\n    }\n"): (typeof documents)["\n    fragment articleCategory on articleCategory_Category {\n      __typename\n      id\n      uri\n      slug\n      groupHandle\n      title\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment entryId on EntryInterface {\n        __typename\n        id\n        title\n        parent {\n            id\n            title\n        }\n    }\n"): (typeof documents)["\n    fragment entryId on EntryInterface {\n        __typename\n        id\n        title\n        parent {\n            id\n            title\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment entryBase on EntryInterface {\n        __typename\n        typeHandle\n        searchScore\n        id\n        uri\n        url\n        slug\n        title\n        sectionHandle\n        postDate @formatDateTime(format: \"j F Y\")\n        parent {\n            id\n            uri\n            title\n        }\n    }\n"): (typeof documents)["\n    fragment entryBase on EntryInterface {\n        __typename\n        typeHandle\n        searchScore\n        id\n        uri\n        url\n        slug\n        title\n        sectionHandle\n        postDate @formatDateTime(format: \"j F Y\")\n        parent {\n            id\n            uri\n            title\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment entries on EntryInterface {\n        ...articleEntry\n        ...articleIndexEntry\n        ...homeEntry\n        ...overviewEntry\n        ...standardEntry\n        ...policyEntry\n    }\n"): (typeof documents)["\n    fragment entries on EntryInterface {\n        ...articleEntry\n        ...articleIndexEntry\n        ...homeEntry\n        ...overviewEntry\n        ...standardEntry\n        ...policyEntry\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CommonLinks($site: [String] = \"default\") {\n    home: entry(site: $site, uri: \"__home__\") {\n      id\n      uri\n      title\n    }\n    blog: entry(site: $site, uri: \"blog\") {\n      id\n      uri\n      title\n    }\n  }\n"): (typeof documents)["\n  query CommonLinks($site: [String] = \"default\") {\n    home: entry(site: $site, uri: \"__home__\") {\n      id\n      uri\n      title\n    }\n    blog: entry(site: $site, uri: \"blog\") {\n      id\n      uri\n      title\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Entry(\n    $site: [String] = \"default\"\n    $preferSites: [QueryArgument] = [\"default\"]\n    $unique: Boolean\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $type: [String]\n) {\n  entry(\n    site: $site\n    preferSites: $preferSites\n    unique: $unique\n    uri: $uri\n    slug: $slug\n    section: $section\n    type: $type\n    ) {\n      ...entries\n  }\n}\n"): (typeof documents)["\n  query Entry(\n    $site: [String] = \"default\"\n    $preferSites: [QueryArgument] = [\"default\"]\n    $unique: Boolean\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $type: [String]\n) {\n  entry(\n    site: $site\n    preferSites: $preferSites\n    unique: $unique\n    uri: $uri\n    slug: $slug\n    section: $section\n    type: $type\n    ) {\n      ...entries\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalSocialMedia on socialMedia_GlobalSet {\n    instagramUrl\n    facebookUrl\n    twitterUrl\n    linkedinUrl\n    __typename\n    id\n    handle\n  }\n"): (typeof documents)["\n  fragment globalSocialMedia on socialMedia_GlobalSet {\n    instagramUrl\n    facebookUrl\n    twitterUrl\n    linkedinUrl\n    __typename\n    id\n    handle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalAbn on abn_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n"): (typeof documents)["\n  fragment globalAbn on abn_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalPhoneNumber on phoneNumber_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n"): (typeof documents)["\n  fragment globalPhoneNumber on phoneNumber_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalCopyright on copyright_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n"): (typeof documents)["\n  fragment globalCopyright on copyright_GlobalSet {\n    label\n    __typename\n    id\n    handle\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globals on GlobalSetInterface {\n    ...globalSocialMedia\n    ...globalAbn\n    ...globalPhoneNumber\n    ...globalCopyright\n  }\n"): (typeof documents)["\n  fragment globals on GlobalSetInterface {\n    ...globalSocialMedia\n    ...globalAbn\n    ...globalPhoneNumber\n    ...globalCopyright\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Global($handle: [String]) {\n    globalSet(handle: $handle) {\n      ...globals\n    }\n  }\n"): (typeof documents)["\n  query Global($handle: [String]) {\n    globalSet(handle: $handle) {\n      ...globals\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Nav($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      __typename\n      id\n      uri\n      url\n      level\n      title\n      newWindow\n      typeLabel\n      classes\n      parent {\n        id\n      }\n      element {\n        uri\n        language\n      }\n      ... on primaryNav_Node {        \n        colorScheme {\n          label\n        }\n      }\n      ... on loginNav_Node {        \n        colorScheme {\n          label\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Nav($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      __typename\n      id\n      uri\n      url\n      level\n      title\n      newWindow\n      typeLabel\n      classes\n      parent {\n        id\n      }\n      element {\n        uri\n        language\n      }\n      ... on primaryNav_Node {        \n        colorScheme {\n          label\n        }\n      }\n      ... on loginNav_Node {        \n        colorScheme {\n          label\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment linkFieldUrl on linkField_Url_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n"): (typeof documents)["\n    fragment linkFieldUrl on linkField_Url_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment linkFieldEntry on linkField_Entry_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n"): (typeof documents)["\n    fragment linkFieldEntry on linkField_Entry_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment linkFieldCustom on linkField_Custom_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n"): (typeof documents)["\n    fragment linkFieldCustom on linkField_Custom_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment linkFieldEmail on linkField_Email_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n"): (typeof documents)["\n    fragment linkFieldEmail on linkField_Email_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment linkFieldPhone on linkField_Phone_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n"): (typeof documents)["\n    fragment linkFieldPhone on linkField_Phone_LinkType {\n        __typename\n        target\n        text\n        newWindow\n        title\n        type\n        url\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment linkField on HyperLinkInterface {\n        ...linkFieldUrl\n        ...linkFieldEntry\n        ...linkFieldCustom\n        ...linkFieldEmail\n        ...linkFieldPhone\n    }\n"): (typeof documents)["\n    fragment linkField on HyperLinkInterface {\n        ...linkFieldUrl\n        ...linkFieldEntry\n        ...linkFieldCustom\n        ...linkFieldEmail\n        ...linkFieldPhone\n    }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;