'use client';

import Card, { CardProps } from '@/components/Card';
import Box from '@/components/ui/Box';
import Btn from '@/components/ui/Btn';
import Txt from '@/components/ui/Txt';
import { gql } from '@/graphql/__generated__';
import { parseLinkField } from '@/lib/parsers/linkField';
import { withFragment } from '@liquorice/gql-utils';
import { toNumber } from '@liquorice/utils';
import { useTranslations } from 'next-intl';

const PRICE_BLOCK = gql(`
    fragment priceBlock on blocks_price_BlockType {
      heading
      minValue
      price
      maxValue
      linkField {
        ...linkField
      }
    }   
`);

const PriceCard = withFragment(PRICE_BLOCK, (data, props: Omit<CardProps, 'item'>) => {
  const t = useTranslations('common');
  const link = parseLinkField(data?.linkField);

  return (
    <Card
      fullHeight
      rounded
      cx={{ p: 'sm' }}
      item={{
        title: data?.heading,
        meta: `${toNumber(data?.minValue)} - ${toNumber(data?.maxValue)} properties`,
      }}
      {...props}>
      <Card.Title />
      <Card.Meta cx={{ mB: 'xs' }} />
      {data?.price && (
        <Box cx={{ mB: 'xs' }}>
          <Txt as="span" color="blue" variant="h2" bold>
            {data?.price}
            <Txt color="blue" variant="sm" bold>
              {' + ' + t('GST')}
            </Txt>
          </Txt>
          <Txt as="p" variant="sm" color="grey" bold>
            {'/ ' + t('property')} {'/ ' + t('month')}
          </Txt>
        </Box>
      )}
      {link && (
        <Box>
          <Btn color="blue" {...link} />
        </Box>
      )}
    </Card>
  );
});

export default PriceCard;
