'use client';

import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/components/Blocks/createBlock';
import ImageAsset from '@/components/ImageAsset';
import Box from '@/components/ui/Box';
import Btn from '@/components/ui/Btn';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import UnstyledAccordion from '@/components/UnstyledAccordion';
import { gql } from '@/graphql/__generated__';
import { parseLinkField } from '@/lib/parsers/linkField';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { useBreakpointMin } from '@/lib/utils/useBreakpoints';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { toNumber, toStringOrNull } from '@liquorice/utils';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { BlockContainer } from '../../BlockContainer';

const TAB_FRAGMENT = gql(`
  fragment tabEntry on tab_Entry {
    id
    imageSingle {
      ...ImageAsset
    }
    iconSingle {
      ...ImageAsset
    }
    title
    heading
    content: htmlContentSimple
    linkField {
      ...linkField
    }
  }  
`);

const TABBED_CONTENT_BLOCK = gql(`
  fragment tabbedContentBlock on blocks_tabbedContent_BlockType {
    heading
    tabMultiple {
      ...tabEntry
    }
  }
`);

const TabbedContentBlock = createBlock(TABBED_CONTENT_BLOCK, ({ data, meta }) => {
  const lg = useBreakpointMin('lg');

  if (!data) return null;

  const tabParser = createFragmentArrayParser(TAB_FRAGMENT, (data) => {
    return data.map((tab) => {
      return {
        id: toNumber(tab.id),
        title: toStringOrNull(tab.title),
        heading: toStringOrNull(tab.heading),
        content: cleanHtml(tab.content),
        link: parseLinkField(tab.linkField),
        imageSingle: tab.imageSingle,
        iconSingle: tab.iconSingle,
      };
    });
  });
  const tabs = tabParser(data.tabMultiple);

  const [activeTab, setActiveTab] = React.useState<number>(tabs[0].id);

  const changeTab = (index: number) => {
    setActiveTab(index);
  };

  return (
    <BlockContainer meta={meta} colorSet="skyLight" paper paddingY="6xl">
      <BlockHeader
        heading={data.heading}
        HeadingProps={{ cx: { textAlign: 'center' } }}
        cx={{ mB: 'md' }}
        alignItems="center"
      />
      {lg && (
        <Flex justifyContent="center" direction="row" spacing="lg" wrap cx={{ mB: '2xl' }}>
          {tabs.map((tab) => (
            <Box key={tab.id}>
              <Btn
                size="normal"
                color={tab.id === activeTab ? 'blue' : 'white'}
                onClick={() => changeTab(tab.id)}>
                {tab.title}
              </Btn>
            </Box>
          ))}
        </Flex>
      )}
      {lg ? (
        <Box colorSet="white" paper cx={{ p: '3xl', borderRadius: 'lg' }}>
          <AnimatePresence mode="wait">
            {tabs.map(
              (tab) =>
                tab.id === activeTab && (
                  <motion.div
                    key={tab.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <Grid key={tab.id}>
                      <Grid.Col md={6}>
                        <Flex cx={{ pR: 'md' }}>
                          <Flex direction="row" spacing="xs">
                            <ImageAsset data={tab.iconSingle} />
                            <Txt as="h3" variant="h3">
                              {tab.heading}
                            </Txt>
                          </Flex>
                          <Txt variant="body" autoMargin html>
                            {tab.content}
                          </Txt>
                          <Box>
                            <Btn scale="medium" variant="outlined" size="large" {...tab.link} />
                          </Box>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col md={6}>
                        <ImageAsset data={tab.imageSingle} />
                      </Grid.Col>
                    </Grid>
                  </motion.div>
                )
            )}
          </AnimatePresence>
        </Box>
      ) : (
        <UnstyledAccordion
          BoxProps={{ cx: { display: 'flex', direction: 'column', rowGap: '2xs' } }}>
          {tabs.map((tab, index) => {
            return (
              <UnstyledAccordion.Item
                key={tab.id}
                {...{
                  index,
                  colorSet: 'white',
                  paper: true,
                  rounded: true,
                  style: { border: 'none' },
                  HandleProps: { cx: { pX: 'md' } },
                  LabelProps: {
                    children: (
                      <Txt as="h6" variant="h6" noMargin>
                        {tab.title}
                      </Txt>
                    ),
                  },
                  children: (
                    <Box cx={{ p: 'md' }}>
                      <Flex direction="row" spacing="xs">
                        <ImageAsset data={tab.iconSingle} />
                        <Txt as="h3" variant="h3">
                          {tab.heading}
                        </Txt>
                      </Flex>
                      <Txt variant="body" autoMargin html>
                        {tab.content}
                      </Txt>
                      <Box cx={{ mT: 'md' }}>
                        <Btn scale="medium" variant="outlined" size="large" {...tab.link} />
                      </Box>
                      <ImageAsset data={tab.imageSingle} />
                    </Box>
                  ),
                }}
              />
            );
          })}
        </UnstyledAccordion>
      )}
    </BlockContainer>
  );
});

export default TabbedContentBlock;
