import { getFragmentData, gql } from '@/graphql/__generated__';
import { LinkFieldFragment } from '@/graphql/__generated__/graphql';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { deleteUndefined, firstNonNullable, toString, toStringOrNull } from '@liquorice/utils';

export const LINK_FIELD_URL_FRAGMENT = gql(`
    fragment linkFieldUrl on linkField_Url_LinkType {
        __typename
        target
        text
        newWindow
        title
        type
        url
    }
`);

export const LINK_FIELD_ENTRY_FRAGMENT = gql(`
    fragment linkFieldEntry on linkField_Entry_LinkType {
        __typename
        target
        text
        newWindow
        title
        type
        url
    }
`);

export const LINK_FIELD_CUSTOM_FRAGMENT = gql(`
    fragment linkFieldCustom on linkField_Custom_LinkType {
        __typename
        target
        text
        newWindow
        title
        type
        url
    }
`);

export const LINK_FIELD_EMAIL_FRAGMENT = gql(`
    fragment linkFieldEmail on linkField_Email_LinkType {
        __typename
        target
        text
        newWindow
        title
        type
        url
    }
`);

export const LINK_FIELD_PHONE_FRAGMENT = gql(`
    fragment linkFieldPhone on linkField_Phone_LinkType {
        __typename
        target
        text
        newWindow
        title
        type
        url
    }
`);

export const LINK_FIELD_FRAGMENT = gql(`
    fragment linkField on HyperLinkInterface {
        ...linkFieldUrl
        ...linkFieldEntry
        ...linkFieldCustom
        ...linkFieldEmail
        ...linkFieldPhone
    }
`);

export interface Link extends Pick<IntrinsicProps<'a'>, 'target' | 'title'> {
  href: string;
  text?: string;
  children?: string;
  download?: boolean;
  //   file?: DocumentAsset;
}

const parseLink = (data: LinkFieldFragment) => {
  const item = firstNonNullable(data);

  let fragment = null;

  if (item?.__typename === 'linkField_Url_LinkType')
    fragment = getFragmentData(LINK_FIELD_URL_FRAGMENT, item);
  if (item?.__typename === 'linkField_Entry_LinkType')
    fragment = getFragmentData(LINK_FIELD_ENTRY_FRAGMENT, item);
  if (item?.__typename === 'linkField_Email_LinkType')
    fragment = getFragmentData(LINK_FIELD_EMAIL_FRAGMENT, item);
  if (item?.__typename === 'linkField_Phone_LinkType')
    fragment = getFragmentData(LINK_FIELD_PHONE_FRAGMENT, item);

  if (!fragment) return null;

  const { target, type, text, title, url: href } = fragment;

  const download = type === 'asset';
  const defaultTarget =
    type && ['asset', 'mail', 'url', 'tel'].includes(type) ? '_blank' : undefined;

  if (!href) return null;

  const field: Link = {
    href: toString(href),
    target: toStringOrNull(target) ?? defaultTarget,
    children: toStringOrNull(text) ?? undefined,
    title: toStringOrNull(title) ?? undefined,
    download,
  };

  return deleteUndefined(field);
};

export const parseLinkField = createFragmentArrayParser(LINK_FIELD_FRAGMENT, (data) => {
  if (!data) return null;

  const item = firstNonNullable(data);

  if (!item) return null;

  return parseLink(item);
});

export const parseLinkFieldMultiple = createFragmentArrayParser(LINK_FIELD_FRAGMENT, (data) => {
  if (!data) return null;

  return data.map((item) => parseLink(item)).filter(Boolean);
});
