import { Color, colorSetVariants } from '@/styles/partials/palette.css';

type ColorScheme =
  | {
      __typename: 'blockScheme' | 'colorScheme';
      label?: string | null;
    }
  | null
  | undefined;

export const parseColorScheme = (data: Maybe<ColorScheme>): Color | undefined => {
  const label = data?.label;

  return label && label in colorSetVariants ? (label as Color) : undefined;
};
