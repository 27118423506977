import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardMetaProps<D extends React.ElementType = 'span'> = TxtProps<D>;

export const CardMeta = <D extends React.ElementType>({ children, ...props }: CardMetaProps<D>) => {
  const { meta } = useCard();
  const content = children ?? meta;

  if (!content) return null;

  return (
    <Txt as="span" variant="sm" bold {...mergePropsClassName(props, style.meta)}>
      {content}
    </Txt>
  );
};
