'use client';

import Box, { BoxProps } from '@/components/ui/Box';
import Container, { ContainerProps } from '@/components/ui/Container';
import { BlockMeta } from '@/lib/parsers/blocks';
import { useForwardedRef } from '@/lib/utils/useForwardedRef';
import { Sprinkles } from '@/styles';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { slugify } from '@liquorice/utils';
import classNames from 'classnames';
import React from 'react';
import { useAppContext } from '../Entry/EntryProvider';
import Grid, { GridColProps, GridProps } from '../ui/Grid';
import * as style from './BlockContainer.css';

export type BlockContainerProps = BoxProps<
  'section',
  style.BlockContainerVariants & {
    children?: React.ReactNode;
    disableContainer?: boolean;
    ContainerProps?: ContainerProps;
    GridProps?: GridProps;
    GridColProps?: GridColProps;
    maxWidth?: ContainerProps['maxWidth'];
    meta?: BlockMeta;
    paddingY?: Sprinkles['paddingY'];
    marginY?: Sprinkles['marginY'] | true;
    overflow?: boolean;
  }
>;

export const BlockContainer = React.forwardRef(function BlockContainer(
  {
    children,
    className: classNameProvided,
    disableContainer,
    marginY = true,
    paddingY,
    overflow,
    cx,
    maxWidth,
    ContainerProps,
    GridProps,
    GridColProps,
    meta,
    ...props
  }: BlockContainerProps,
  forwardedRef?: React.ForwardedRef<HTMLElement>
) {
  const ref = useForwardedRef(forwardedRef);
  const isLast = !!meta?.last;
  const isFirst = !!meta?.first;

  let inner = children;

  const { entry } = useAppContext() ?? {};
  const isStandard = entry?.__typename === 'standard_Entry';
  const isArticle = entry?.__typename === 'article_Entry';

  const customGridColProps = {
    ...GridColProps,
    ...(isStandard && {
      offsetMd: 2,
      md: 8,
    }),
  };

  if (!disableContainer)
    inner = (
      <Container
        {...{
          maxWidth,
          disablePadding: isArticle,
          ...mergePropsClassName(ContainerProps, style.container({ overflow })),
        }}>
        <Grid {...mergePropsClassName(GridProps, style.grid)}>
          <Grid.Col {...mergePropsClassName(customGridColProps, style.gridCol)}>
            {children}
          </Grid.Col>
        </Grid>
      </Container>
    );

  const className = classNames(
    classNameProvided,
    style.root({
      last: isLast,
      first: isFirst,
    })
  );

  const blockLength = meta?.length;

  const blockSpace = isStandard ? 'lg' : '5xl';

  const usesMargin = marginY === true;
  const usesPadding = paddingY !== undefined && paddingY !== 'none';

  let blockTopMargin = usesMargin ? blockSpace : marginY;
  let blockBottomMargin = usesMargin ? blockSpace : marginY;

  if (usesPadding) blockBottomMargin = 'none';
  if (usesPadding) blockTopMargin = 'none';

  if (isFirst) blockTopMargin = 'none';
  if (isLast && usesPadding) blockBottomMargin = 'none';
  if (usesPadding && isLast && isStandard) blockTopMargin = '5xl';
  if (!usesPadding && isLast && isStandard) blockBottomMargin = '5xl';

  // If there is only one block and it does not use padding
  if (blockLength === 1 && !usesPadding) blockBottomMargin = '5xl';

  return (
    <Box
      as="section"
      className={className}
      id={meta?.anchor ? slugify(meta.anchor) : undefined}
      data-block={`${meta?.typename.split('_')[1]}-${meta?.index}`}
      ref={ref}
      {...props}
      cx={{
        mT: blockTopMargin,
        mB: blockBottomMargin,
        pY: paddingY,
        ...cx,
      }}>
      {inner}
    </Box>
  );
});
