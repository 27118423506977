import Flex, { FlexProps } from '@/components/ui/Flex';
import Txt, { TxtProps } from '@/components/ui/Txt';
import { mergePropsClassName } from '@liquorice/utils';
import React from 'react';
import * as style from './BlockHeader.css';

export type BlockHeaderProps = FlexProps<
  'div',
  {
    children?: React.ReactNode;
    className?: string;
    heading?: React.ReactNode;
    HeadingProps?: TxtProps;
    subHeading?: React.ReactNode;
    SubHeadingProps?: TxtProps;
    content?: React.ReactNode;
    ContentProps?: TxtProps;
  } & style.BlockHeaderVariants
>;

const BlockHeader = ({
  children,
  heading,
  HeadingProps,
  subHeading,
  SubHeadingProps,
  content,
  ContentProps,
  maxWidth,
  ...props
}: BlockHeaderProps) => {
  return (
    (heading || subHeading || content) && (
      <Flex alignItems="start" {...mergePropsClassName(props, style.root({ maxWidth }))}>
        {heading && (
          <Txt variant="h2" as="h2" {...HeadingProps}>
            {heading}
          </Txt>
        )}
        {subHeading && (
          <Txt variant="h4" as="h4" {...SubHeadingProps}>
            {subHeading}
          </Txt>
        )}
        {content && (
          <Txt variant="body" html {...ContentProps}>
            {content}
          </Txt>
        )}
        {children}
      </Flex>
    )
  );
};

export default BlockHeader;
