import Box, { BoxComponent, BoxProps } from '@/components/ui/Box';
import { Polymorphic } from '@/components/ui/Polymorphic';
import { GridColStyleProps, useGridColStyle } from './useGridStyle';

import React from 'react';

type GridColBaseProps = GridColStyleProps;

export type GridColProps<C extends React.ElementType = 'div', P = NoProps> = BoxProps<
  C,
  GridColBaseProps & P
>;

export const GridCol: BoxComponent<'div', GridColBaseProps> = React.forwardRef(function GridCol<
  C extends React.ElementType
>(props: GridColProps<C>, ref?: Polymorphic.Ref<C>) {
  const boxProps = useGridColStyle(props) as BoxProps;

  return <Box ref={ref} {...boxProps} />;
});
