'use client';

import { useBreakpointMin } from '@/lib/utils/useBreakpoints';
import Container from '../ui/Container';

const PageHeaderImageWrapper = ({ children }: React.PropsWithChildren) => {
  const lg = useBreakpointMin('lg');

  return <Container disablePadding={!lg}>{children}</Container>;
};

export default PageHeaderImageWrapper;
