'use client';

import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/components/Blocks/createBlock';
import Grid from '@/components/ui/Grid';
import { gql } from '@/graphql/__generated__';
import { useTranslations } from 'next-intl';
import { BlockContainer } from '../../BlockContainer';
import Illustration from './Illustration';

const NEWSLETTER_SIGNUP_BLOCK = gql(`
  fragment newsletterSignupBlock on blocks_newsletterSignup_BlockType {
      __typename
  }   
`);

const NewsletterSignupBlock = createBlock(NEWSLETTER_SIGNUP_BLOCK, ({ data, meta }) => {
  const t = useTranslations('newsletter');

  if (!data) return null;

  return (
    <BlockContainer meta={meta} colorSet="blue" paper paddingY="6xl">
      <Grid>
        <Grid.Col>
          <BlockHeader
            heading={t('heading')}
            HeadingProps={{ color: 'white' }}
            subHeading={t('subheading')}
            SubHeadingProps={{ color: 'white', variant: 'h6' }}
          />
        </Grid.Col>
      </Grid>
      <Illustration
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}
      />
    </BlockContainer>
  );
});

export default NewsletterSignupBlock;
